body{
  background-color: var(--blanco);
}
.padding_width_taiwan{
  padding: 35px 10px;
}
.de_taiwan{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo_hidalgo_taiwan{
  height: 200px;
  margin-bottom: 35px;
}
.fondo_taiwan{
  /*background-image:url('./img/taiwan/fondo_taiwan.jpg');*/
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  /*
  background-color: #10121d;
  background-size: auto 100%;
  */
  /*
  background-size:cover;
  background-attachment: fixed;
  */
  /*padding: 50px;*/
}
.titulo_hidalgo{
  color: var(--gold);
  font-family: var(--carmina);
  font-size: 70px;
}
.sub_hidalgo{
  font-size: 30px;
  font-family: var(--titulos);
  text-transform: uppercase;
}
.flex_taiwan{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap:wrap;
  margin-top: 50px;
}
.flex_taiwan a{
  text-decoration: none;
}
.de_flex_taiwan{
  margin: 0 15px;
  margin-top: 30px;
  cursor: pointer;
}
.de_flex_taiwan img{
  height: 120px;
}
.de_icono_taiwan div{
  margin-top: 35px;
  border-radius: 25px;
  padding: 15px 50px;
  color: var(--blanco);
  font-family: var(--titulos);
  font-size: 20px;
  background-color: var(--wine);
  transition: var(--all);
}
.de_icono_taiwan div:hover{
  background-color: var(--gold);
}
.new_taiwan{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.circulo_taiwan{
  height: 300px;
  margin: 0 20px;
}
.iconos_taiwan{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: calc((100% - 340px) / 2);
}
.iconos_taiwan a{
  text-decoration: none;
}
.de_icono_taiwan{
  margin: 0 15px;
  margin-top: 30px;
  cursor: pointer;
  width: 40%;
}
.de_icono_taiwan img{
  width: 50%;
}
.de_icono_taiwan div{
  margin-top: 15px;
  border-radius: 25px;
  padding: 10px 20px;
  color: var(--blanco);
  font-family: var(--titulos);
  font-size: 16px;
  background-color: var(--wine);
  transition: var(--all);
}
@media screen and (max-width: 1100px) {
  .de_icono_taiwan div{
    margin-top: 10px;
    padding: 5px 10px;
  }
}
@media screen and (max-width: 1000px) {
  .de_icono_taiwan div{
    margin-top: 10px;
    padding: 2.5px 5px;
  }
}
@media screen and (max-width: 959px) {
  .de_icono_taiwan div{
    margin-top: 15px;
    padding: 10px 20px;
  }
  .de_icono_taiwan{
    margin: 0 15px;
    margin-top: 30px;
    width: 80%;
  }
  .de_icono_taiwan img{
    width: 50%;
  }
  .fondo_taiwan{
    background-size:cover;
    background-attachment: fixed;
  }
}
@media screen and (max-width: 750px) {
  .new_taiwan{
    flex-flow: column;
  }
  .iconos_taiwan{
    width: 80%;
  }
  .de_icono_taiwan{
    margin: 0 15px;
    margin-top: 30px;
    width: 60%;
  }
  .de_icono_taiwan img{
    width: 50%;
  }
  .circulo_taiwan{
    margin-top: 35px;
  }
}
@media screen and (max-width: 600px) {
  .logo_hidalgo_taiwan{
    width: 100%;
    height: auto;  
  }
  .titulo_hidalgo{
    font-size: 40px;
  }
}
@media screen and (max-width: 500px) {
  .de_icono_taiwan{
    width: 100%;
  }
}
@media screen and (max-width: 350px) {
  .circulo_taiwan{
    height: auto;
    width: 80%;
  }
}